<template>
  <!-- 内容 -->
  <div class="Box">
    <div class="p15 flex">
      <svg
        style="width: 20px; height: 20px"
        t="1617329367234"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2922"
        width="200"
        height="200"
      >
        <path
          d="M384 512L731.733333 202.666667c17.066667-14.933333 19.2-42.666667 4.266667-59.733334-14.933333-17.066667-42.666667-19.2-59.733333-4.266666l-384 341.333333c-10.666667 8.533333-14.933333 19.2-14.933334 32s4.266667 23.466667 14.933334 32l384 341.333333c8.533333 6.4 19.2 10.666667 27.733333 10.666667 12.8 0 23.466667-4.266667 32-14.933333 14.933333-17.066667 14.933333-44.8-4.266667-59.733334L384 512z"
          p-id="2923"
        ></path>
      </svg>
      <div class="f18 textc flex1">功能介绍</div>
    </div>
    <div class="p15">
      <div
        class="flex align-stretch justify-center align-center mt30 margin20-b"
      >
        <img style="height: 52px; width: 51px" src="../assets/images/1081.png" alt="" />
        <div class="flex flex-column justify-between mlr12">
          <div class="f27 fw600">干细胞之家</div>
          <div class="f20 text333">
            <span>健康</span><span class="mlr12">长寿</span><span>财富</span>
          </div>
        </div>
      </div>
      <!--内容-->
      <div class="plr12 radius10 bg-white mt30 padding30-b">
        <div class="pl10 pr10 padding10-b ptb15 mt6 bbox">
          <div class="lh30 f14 texti2">
            平台专注于干细胞应用技术的推广，运用互联网、大数据、云计算等技术手段，以干细胞应用为核心，以客户需求为导向。
          </div>
        </div>
        <div class="pl10 pr10">
          <div class="fw600">核心板块</div>
          <div class="lh30 f14 text333"><i class="radius8 mr5"></i>机构</div>
          <div class="lh30 f14 text333"><i class="radius8 mr5"></i>专家</div>
          <div class="lh30 f14 text333">
            <i class="radius8 mr5"></i>高精尖产品
          </div>
          <div class="lh30 f14 text333">
            <i class="radius8 mr5"></i>公益事业
          </div>
          <div class="lh30 f14 text333">
            <i class="radius8 mr5"></i>学会协会
          </div>
          <div class="lh30 f14 text333"><i class="radius8 mr5"></i>创投</div>
          <div class="lh30 f14 text333"><i class="radius8 mr5"></i>资讯</div>
          <div class="lh30 f14 text333"><i class="radius8 mr5"></i>政策</div>
          <div class="lh30 f14 text333"><i class="radius8 mr5"></i>供应链</div>
          <div class="lh30 f14 text333"><i class="radius8 mr5"></i>自媒体</div>
        </div>
        <div class="mt20 pl10 lh40 f14">
          <span>为社会提供前沿的生物科技信息和产品；</span><br>
          <span>为企业提供优质的客户流量和服务；</span><br>
          <span>为创业者搭建先进的创业模式和机会；</span><br>
          <span>争取打造全球极具影响立的干细胞平台。</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import "../assets/css/style.css";
@import "../assets/css/common.css";
</style>